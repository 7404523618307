<div class="modal-header">
  <h6 class="text-start m-0">{{ title }}</h6>
  <fa-icon
    [icon]="['fas', 'xmark']"
    class="font-20-px"
    role="button"
    (click)="activeModal.close()"
  />
</div>
<div class="d-center flex-column align-items-start m-3">
  <h4>Step 1</h4>
  <h6>1. Scan the QR code</h6>
  <h6>2. Install the Buzz Ring app</h6>
  <h6>
    3. After installing, the app, scan the second QR code in
    <strong
      class="c-pointer text-primary text-decoration-underline"
      (click)="closePreview()"
      >Step 2</strong
    >
  </h6>
</div>
<div class="d-flex justify-content-around text-start single-box p-3">
  <div class="d-flex app-qr gap-5 mt-2">
    <div
      class="d-flex flex-column gap-2 align-items-center qr-box p-3 c-pointer position-relative"
      *ngIf="!showStoreQr"
    >
      <img
        class="h-44-px"
        src="/assets/images/logos/googlePlay.png"
        (click)="handleClick('playStore')"
      />
      <fa-icon
        *ngIf="showPlayQr"
        [icon]="['fas', 'xmark']"
        class="position-absolute top-0 start-100 translate-middle badge bg-danger p-1 font-16-px c-pointer c-icon-color"
        (click)="closePreview()"
      />
      <qrcode
        *ngIf="showPlayQr"
        [qrdata]="playStore"
        [width]="200"
        [errorCorrectionLevel]="'Q'"
      >
      </qrcode>
    </div>
    <div
      class="d-flex flex-column gap-2 align-items-center qr-box p-3 c-pointer position-relative"
      *ngIf="!showPlayQr"
    >
      <img
        class="h-44-px"
        src="/assets/images/logos/appStore.png"
        (click)="handleClick('appStore')"
      />
      <fa-icon
        *ngIf="showStoreQr"
        [icon]="['fas', 'xmark']"
        class="position-absolute top-0 start-100 translate-middle badge bg-danger p-1 font-16-px c-pointer c-icon-color"
        (click)="closePreview()"
      />
      <!-- <h2 *ngIf="showStoreQr" class="mt-5">coming soon</h2> -->
      <qrcode
        *ngIf="showStoreQr"
        [qrdata]="appStore"
        [width]="200"
        [errorCorrectionLevel]="'Q'"
      >
      </qrcode>
    </div>
  </div>
</div>
<ng-container *ngIf="!showStoreQr && !showPlayQr">
  <div class="d-center flex-column align-items-start m-3">
    <h4>Step 2</h4>
    <h6>1. To Register Scan or Download to Images on your device</h6>
    <h6>2. Open your QR Scanner app</h6>
    <h6>3. Select option to open image</h6>
    <h6>4. Scan downloaded QR code</h6>
  </div>
  <div class="d-flex flex-column">
    <div class="d-center flex-column gap-2 mb-4">
      <!-- <span class="mb-2">Kindly use your device to scan the QR code to register.</span> -->
      <qrcode
        #qrCode
        [qrdata]="qrLink"
        [width]="300"
        [elementType]="'img'"
        [cssClass]="'center'"
        [margin]="4"
        [scale]="8"
        [errorCorrectionLevel]="'Q'"
      >
      </qrcode>
      <button
        class="btn btn-primary d-flex gap-2"
        (click)="saveAsImage(qrCode)"
      >
        <fa-icon [icon]="['fas', 'download']" />
        <span>Download</span>
      </button>
    </div>
  </div>
</ng-container>
