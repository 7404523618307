<div class="modal-body text-start" #focusElement>
  <div class="text-center">
    <img
      [src]="calldata.ProfilePicName"
      class="h-100-px w-100-px rounded-circle"
      alt="image"
      onerror="this.onerror=null;this.src='/assets/images/avtar/placeholder-user.png';"
    />
    <h4 class="mt-5">{{ calldata?.Username }}</h4>
    <span class="mb-3">{{ title }}</span>

    <div class="m-2">
      <button
        type="button"
        class="btn btn-sm btn-danger ms-2 font-20-px rounded-circle"
        (click)="hangUpCall()"
      >
        <fa-icon class="c-icon-color" [icon]="['fas', 'phone-slash']"></fa-icon>
      </button>
    </div>
  </div>
</div>
