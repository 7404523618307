<div class="mt-2 border rounded">
  <ng-container *ngIf="post; else noPost">
    <div class="d-flex p-2 position-relative" (mouseout)="showHoverBox = false">
      <img
        class="w-24-px h-24-px rounded"
        loading="lazy"
        data-src="{{ post?.ProfilePicName }}"
        onerror="this.onerror=null;this.src='/assets/images/avtar/placeholder-user.png';"
        alt="avatar"
      />
      <div
        class="d-flex flex-column h6 m-0 ms-2"
        *ngIf="post?.Username || post?.FirstName"
      >
        <div
          class="d-flex align-items-center cursor c-padding posted-wrap-text"
        >
          <div class="d-flex align-items-center" (mouseover)="showHoverBox = true">
            {{ post?.Username || post?.FirstName }}
            <div>
              <fa-icon
                class="ms-2 me-1 font-12-px cursor"
                [icon]="['fas', 'clock']"
                placement="bottom"
              />
              {{ post?.postcreationdate | dateDay }}
            </div>
          </div>
        </div>
      </div>
         <!-- hoverbox -->
         <div
         [class.show]="showHoverBox"
         class="hover-box-container shadow"
         (mouseover)="showHoverBox = true"
         *ngIf="(breakpointService?.screen | async).lg.gatherThen"
       >
         <div class="d-flex flex-column">
           <img
             class="w-48-px h-48-px rounded-4"
             loading="lazy"
             data-src="{{ post?.ProfilePicName || '/assets/images/avtar/placeholder-user.png'}}"
             onerror="this.onerror=null;this.src='/assets/images/avtar/placeholder-user.png';"
             alt="avatar"
           />
           <div class="d-flex gap-3 align-items-baseline">
             <a class="h6 cursor mt-2" [href]="webUrl + 'settings/view-profile/' + post.profileid" target="_blank">
               &#64;{{ (post?.Username || post?.FirstName)?.split(" ")?.join("") }}
             </a>
             
             <button>
               <a
                 class="droplist d-flex align-items-center gap-2"
                 [appCopyClipboard]="opyData(post)"
               >
                 <fa-icon [icon]="['fas', 'copy']" />
               </a>
             </button>
           </div>
           <div
             class="d-flex mt-2"
             *ngIf="
               profileId != post.profileid && tokenService.getCredentials()
             "
           >
             <button>
               <a
                 class="droplist d-flex align-items-center gap-2"
                 (click)="selectMessaging(post)"
               >
                 <fa-icon [icon]="['fas', 'envelope']" />
                 <span>Messaging</span>
               </a>
             </button>
             <span class="me-2 ms-2">|</span>
             <button>
               <a
                 class="droplist d-flex align-items-center gap-2"
                 routerLink="/platforms"
                 target="_blank"
               >
                 <fa-icon [icon]="['fas', 'table-list']" />
                 <span>Platforms</span>
               </a>
             </button>
           </div>
         </div>
       </div>
    </div>
    <div class="p-2 cursor" (click)="redirectToParentProfile(post)">
      <p
        class="post-description mdtxt m-0"
        [innerHTML]="post.postdescription | noSanitize" hoverDropdown
      ></p>
      <ng-container *ngIf="descriptionimageUrl">
        <app-img-preview [src]="descriptionimageUrl" classes="w-100" />
      </ng-container>
      <ng-container *ngIf="post?.pdfUrl">
        <div class="d-flex align-items-center">
          <fa-icon
            [icon]="['fas', 'file-pdf']"
            class="c-pointer close-icon font-60-px"
          />
          <span class="pdf-name c-pointer">
            {{ post.pdfUrl.split("/")[3].replaceAll("%", " ") }}
          </span>
        </div>
      </ng-container>
      <ng-container *ngIf="post?.streamname">
        <!-- <div class="video-albumname mb-3">
          {{ post.albumname }}
        </div> -->
        <!-- <app-img-preview [src]="post?.thumbfilename" classes="w-100" /> -->
        <video
        data-src="{{post.thumbfilename}}"
        [src]="post?.streamname"
        controls
        class="w-100 mt-1 rounded"
        [autoplay]="false"
        poster="{{post.thumbfilename}}"
        ></video>
    
      </ng-container>

      <app-post-meta-data-card [post]="post" />
      <ng-container *ngIf="post?.imagesList">
        <app-img-layout [post]="post" />
      </ng-container>
      <ng-container *ngIf="post?.imageUrl">
        <app-img-preview [src]="post?.imageUrl" classes="w-100" />
      </ng-container>
      <!-- <ng-container *ngIf="post?.streamname">
        <app-img-preview [src]="post?.thumbfilename" classes="w-100" />
      </ng-container> -->
    </div>
  </ng-container>
  <ng-template #noPost>
    <div class="d-flex justify-content-center p-2">
      <span>This post is unavailable!</span>
    </div>
  </ng-template>
</div>
