import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { CustomerService } from '../../services/customer.service';
import { MessageService } from '../../services/message.service';
import { Router } from '@angular/router';
import { SocketService } from '../../services/socket.service';
import { SharedService } from '../../services/shared.service';

@Component({
  selector: 'app-add-user-to-group-modal',
  templateUrl: './add-user-to-group-modal.component.html',
  styleUrl: './add-user-to-group-modal.component.scss'
})
export class AddUserToGroupModalComponent implements OnInit {
  @Input() cancelButtonLabel: string = 'Cancel';
  @Input() confirmButtonLabel: string = 'Done';
  @Input() title: string = 'Add to Group';
  @Input() message: string;
  @Input() data: any;
  @Input() groupId: number;
  profileId: number;
  searchText = '';
  groupList: any = [];

  addedInvitesList: any[] = [];

  @ViewChild('userSearchDropdownRef', { static: false, read: NgbDropdown })
  userSearchNgbDropdown: NgbDropdown;
  isOpenUserMenu = false;

  constructor(
    public activateModal: NgbActiveModal,
    private customerService: CustomerService,
    private sharedService: SharedService,
    private messageService: MessageService,    
  ) {
    this.profileId = +localStorage.getItem('profileId');
  }

  ngOnInit(): void {    
    if(this.profileId && this.data && this.data.Id){
      this.getUserGroupList()
    }      
  }

  getUserGroupList():void{
    this.messageService.getUserGroupList(this.searchText, this.profileId, this.data.Id).subscribe({
      next: (res: any) => {
        if (res?.data?.length > 0) {
          this.groupList = res?.data
        } else {
          this.groupList = [];;
        }
      },
      error: () => {
        this.groupList = [];
      },
    });
  }

  // getgroupList(): void {
  //   this.customerService.getProfileList(this.searchText).subscribe({
  //     next: (res: any) => {
  //       if (res?.data?.length > 0) {
  //         const groupList = res.data.filter((user: any) => {
  //           return user.Id !== this.sharedService?.userData?.profileId;
  //         });
  //         this.groupList = groupList.filter((user) => {
  //           return !this.addedInvitesList.some(
  //             (invite) => invite.Id === user.Id
  //           );
  //         });
  //         this.userSearchNgbDropdown.open();
  //       } else {
  //         this.groupList = [];
  //         this.userSearchNgbDropdown.close();
  //       }
  //     },
  //     error: () => {
  //       this.groupList = [];
  //       this.userSearchNgbDropdown.close();
  //     },
  //   });
  // }

  // addProfile(user) {
  //   this.addedInvitesList.push(user);
  //   this.searchText = '';
  // }

  // removeUser(item) {
  //   this.addedInvitesList = this.addedInvitesList.filter(
  //     (user) => user.Id !== item.Id
  //   );
  // }
  

  addUserToGroup(item) {
    const groupData = {
      profileId: this.profileId,
      groupName: item.groupName,
      profileIds: [this.data.Id],
      groupId: item.groupId,
      isUpdate: true
    };
    this.activateModal.close(groupData);
  }
}

