<div class="main-container bg-color">
  <nav
    class="navbar navbar-light bg-white px-6 px-sm-10 auth-btns header-section"
  >
    <div class="d-flex flex-column">
      <img
        src="assets/images/landingpage/freedom-buzz.png"
        class="h-60-px pt-2"
        alt=""
      />
      <h6 class="text-danger text-end">BETA</h6>
    </div>
    <div class="d-lg-none d-md-none d-block c-button-landing">
      <fa-icon [icon]="['fas', 'bars']" (click)="mobileMenu()" />
    </div>
    <div class="landing-btn d-none d-md-block d-lg-block">
      <button class="btn btn-primary alt third w-80-px" routerLink="/login">
        Login
      </button>
      <button
        class="btn btn-outline alt third w-80-px ms-2"
        routerLink="/register"
      >
        Signup
      </button>
    </div>
  </nav>

  <div (click)="closeMenu()">
    <div class="text-center py-10 custom-height position-relative">
      <div class="container h-100">
        <div
          class="d-flex flex-column justify-content-center h-100 main-banner align-items-start"
        >
          <h1
            class="text-white c-fonts py-6"
            style="font-size: 50px; padding-bottom: 10px; font-weight: 600"
          >
            Local Community Driven
          </h1>
          <p class="text-white c-fonts pt-1">
            Umbrella Platform for All Freedom Based Projects Worldwide
          </p>
          <ul class="world-wide-text">
            <li class="pb-1 text-white text-s">
              <i> Worldwide Freedom is the Buzzzz!</i>
            </li>
            <li class="pb-1 text-white text-s">
              <i> No AI and we DO NOT sell your data</i>
            </li>
          </ul>
        </div>
      </div>
      <img
        src="assets/images/banner/bg.png"
        alt="bg"
        style="position: absolute; left: 0; height: 100%; width: 100%; top: 0"
      />
    </div>
    <div class="bg-white">
      <div class="answers_main">
        <div class="container">
          <div class="row align-items-center mb-20">
            <div class="col-md-6">
              <img src="/assets/images/landingpage/freedom-tube.png" alt="" />
            </div>
            <div class="col-md-6">
              <div class="sub-container d-block">
                <p class="sub-text c-width py-lg-5">
                  <strong class="head-text">Freedom.buzz</strong> is a
                  decentralized platform that promotes freedom based projects
                  worldwide
                </p>
                <div>
                  <p class="head-text sub-text">
                    <strong class="head-text">
                      Freedom.buzz is end user regulated:</strong
                    >
                  </p>
                  <p
                    class="sub-text"
                    style="font-size: 18px; margin-bottom: 0px"
                  >
                    - End users are subscribed to all end users and channels and
                    pages
                  </p>
                  <p
                    class="sub-text"
                    style="font-size: 18px; margin-bottom: 0px"
                  >
                    - If you do not like something or someone, Unsubscribe
                  </p>
                  <p
                    class="sub-text"
                    style="font-size: 18px; margin-bottom: 0px"
                  >
                    - If you like something or someone, you can See First
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <h1
            class="text-center text-color mb-0"
            style="font-weight: 600; font-size: 50px; padding-bottom: 10px"
          >
            We Have Answers
          </h1>
          <div class="container">
            <div class="answers_token">
              <img
                class="shadow rounded"
                alt="answer"
                loading="lazy"
                width="308"
                height="308"
                decoding="async"
                data-nimg="1"
                style="color: transparent; height: 100%"
                src="/assets/images/landingpage/wwf.png"
              />
              <img
                class="shadow rounded"
                alt="answer"
                loading="lazy"
                width="308"
                height="308"
                decoding="async"
                data-nimg="1"
                style="color: transparent; height: 100%"
                src="/assets/images/landingpage/freedombuzz.png"
              />
              <img
                class="shadow rounded"
                alt="answer"
                loading="lazy"
                width="308"
                height="308"
                decoding="async"
                data-nimg="1"
                style="color: transparent; height: 100%"
                src="/assets/images/landingpage/freedomtube.png"
              />
              <img
                class="shadow rounded"
                alt="answer"
                loading="lazy"
                width="308"
                height="308"
                decoding="async"
                data-nimg="1"
                style="color: transparent; height: 100%"
                src="/assets/images/landingpage/1776token.png"
              />
            </div>
          </div>
          <!-- <div class="container-fluid">
            <div class="slider-container" #sliderContainer>
              <div class="slider-wrapper" #sliderWrapper>
                <div class="slide">
                  <img
                    class="shadow rounded"
                    loading="lazy"
                    width="308"
                    height="308"
                    decoding="async"
                    data-nimg="1"
                    style="color: transparent; height: 100%"
                    src="/assets/images/landingpage/wwf.png"
                    alt="Image 1"
                  />
                  <img
                    class="shadow rounded"
                    alt="Image 2"
                    loading="lazy"
                    width="308"
                    height="308"
                    decoding="async"
                    data-nimg="1"
                    style="color: transparent; height: 100%"
                    src="/assets/images/landingpage/freedombuzz.png"
                  />
                  <img
                    class="shadow rounded"
                    alt="Image 3"
                    loading="lazy"
                    width="308"
                    height="308"
                    decoding="async"
                    data-nimg="1"
                    style="color: transparent; height: 100%"
                    src="/assets/images/landingpage/freedomtube.png"
                  />
                  <img
                    class="shadow rounded"
                    alt="Image 4"
                    loading="lazy"
                    width="308"
                    height="308"
                    decoding="async"
                    data-nimg="1"
                    style="color: transparent; height: 100%"
                    src="/assets/images/landingpage/freedomtube.png"
                  />
                  <img
                    class="shadow rounded"
                    alt="Image 5"
                    loading="lazy"
                    width="308"
                    height="308"
                    decoding="async"
                    data-nimg="1"
                    style="color: transparent; height: 100%"
                    src="/assets/images/landingpage/1776token.png"
                  />
                </div>
                <div class="slide">
                  <img
                    class="shadow rounded"
                    loading="lazy"
                    width="308"
                    height="308"
                    decoding="async"
                    data-nimg="1"
                    style="color: transparent; height: 100%"
                    src="/assets/images/landingpage/wwf.png"
                    alt="Image 1"
                  />
                  <img
                    class="shadow rounded"
                    alt="Image 2"
                    loading="lazy"
                    width="308"
                    height="308"
                    decoding="async"
                    data-nimg="1"
                    style="color: transparent; height: 100%"
                    src="/assets/images/landingpage/freedombuzz.png"
                  />
                  <img
                    class="shadow rounded"
                    alt="Image 3"
                    loading="lazy"
                    width="308"
                    height="308"
                    decoding="async"
                    data-nimg="1"
                    style="color: transparent; height: 100%"
                    src="/assets/images/landingpage/freedomtube.png"
                  />
                  <img
                    class="shadow rounded"
                    alt="Image 4"
                    loading="lazy"
                    width="308"
                    height="308"
                    decoding="async"
                    data-nimg="1"
                    style="color: transparent; height: 100%"
                    src="/assets/images/landingpage/freedomtube.png"
                  />
                  <img
                    class="shadow rounded"
                    alt="Image 5"
                    loading="lazy"
                    width="308"
                    height="308"
                    decoding="async"
                    data-nimg="1"
                    style="color: transparent; height: 100%"
                    src="/assets/images/landingpage/1776token.png"
                  />
                </div>
                <div class="slide">
                  <img
                    class="shadow rounded"
                    loading="lazy"
                    width="308"
                    height="308"
                    decoding="async"
                    data-nimg="1"
                    style="color: transparent; height: 100%"
                    src="/assets/images/landingpage/wwf.png"
                    alt="Image 1"
                  />
                  <img
                    class="shadow rounded"
                    alt="Image 2"
                    loading="lazy"
                    width="308"
                    height="308"
                    decoding="async"
                    data-nimg="1"
                    style="color: transparent; height: 100%"
                    src="/assets/images/landingpage/freedombuzz.png"
                  />
                  <img
                    class="shadow rounded"
                    alt="Image 3"
                    loading="lazy"
                    width="308"
                    height="308"
                    decoding="async"
                    data-nimg="1"
                    style="color: transparent; height: 100%"
                    src="/assets/images/landingpage/freedomtube.png"
                  />
                  <img
                    class="shadow rounded"
                    alt="Image 4"
                    loading="lazy"
                    width="308"
                    height="308"
                    decoding="async"
                    data-nimg="1"
                    style="color: transparent; height: 100%"
                    src="/assets/images/landingpage/freedomtube.png"
                  />
                  <img
                    class="shadow rounded"
                    alt="Image 5"
                    loading="lazy"
                    width="308"
                    height="308"
                    decoding="async"
                    data-nimg="1"
                    style="color: transparent; height: 100%"
                    src="/assets/images/landingpage/1776token.png"
                  />
                </div>
              </div>
              <button class="prev" #prevButton>Prev</button>
              <button class="next" #nextButton>Next</button>
            </div>
          </div> -->
        </div>
      </div>
    </div>
    <div class="container">
      <div class="c-footer bg-color py-6">
        <p class="footer-txt" style="font-size: 13px; text-align: left">
          Worldwide Freedom Foundation and its Members around the world have set
          this decentralized platform free. Freedom.buzz does not have a
          managing or governing organization. Its Members are 1776 Token
          Masternode Owners. We believe in free speech, and we live with love
          and truth. This decentralized platform does not sell end user data. We
          are not interested in profits from this platform. We are interested in
          advancing humanity within organic form and seeing that children live
          safe and happy lives. If you desire to help fund this project, become
          a 1776 Masternode owner. Inquire within.
        </p>
        <a href="/privacy-policy" class="privacy-policy-button"
          >Privacy Policy</a
        >
      </div>
    </div>
  </div>
</div>
<ng-container *ngIf="mobileMenuToggle">
  <div class="main-area p-5 profile-content">
    <div class="head-area">
      <div class="d-flex gap-3">
        <div class="text-area">
          <h6 class="m-0 mb-1"></h6>
        </div>
      </div>
    </div>
    <div class="d-flex flex-row-reverse c-button-landing">
      <button class="c-btn" (click)="closeMenu()">
        <fa-icon [icon]="['fas', 'xmark']" />
      </button>
    </div>
    <ul class="ps-0">
      <li>
        <a class="mdtxt text-black" routerLink="/login" (click)="closeMenu()">
          Login
        </a>
      </li>
      <li>
        <a
          class="mdtxt text-black"
          routerLink="/register"
          (click)="closeMenu()"
        >
          Sign up
        </a>
      </li>
    </ul>
  </div>
</ng-container>
