<div class="d-center gap-3">
  <div class="d-flex flex-column gap-5 m-5">
    <div class="d-center">
      <div class="qr-box">
        <img [src]="image" height="44">
      </div>
    </div>
    <qrcode [qrdata]="store" [width]="250" [errorCorrectionLevel]="'Q'"> </qrcode>
  </div>
</div>
