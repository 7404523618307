<div class="modal-header justify-content-end">
  <fa-icon [icon]="['fas', 'xmark']" class="font-20-px" role="button" (click)="activeModal.close('cancel')" />
</div>

<div class="modal-body d-flex flex-column align-items-center">
   <h3 class="mb-5 mb-md-7">HOW TO USE FREEDOM.BUZZ</h3>
   <h5 class="mb-2 mb-md-4">Freedom.buzz is end user regulated</h5>
   <ul class="text-center">
    <li class="fw-bold">- End users are subscribed to all end users and channels and pages</li>
    <li>- If you do not like something or someone, <span class="fw-bold">Unsubscribe</span></li>
    <li>- If you like something or someone, you can <span class="fw-bold">See First</span></li>
   </ul>
</div>
