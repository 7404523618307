<div class="modal-header">
  <h6 class="text-start m-0">{{ title }}</h6>
  <fa-icon
    [icon]="['fas', 'xmark']"
    class="font-20-px"
    role="button"
    (click)="activateModal.close()"
  />
</div>

<div class="modal-body text-start">
  <h6 class="text-start m-0">
    <div class="title-area text-area text-center mt-2" *ngIf="groupList.length == 0">
      No Groups Available
    </div>
  </h6>
  <div class="bg-box pe-3 rounded-2">
    <div class="header-menu cus-scrollbar-pending overflow-auto">
      <div
        class="single-item messages-area d-flex justify-content-between align-items-baseline"
        *ngFor="let item of groupList"
      >
        <div
          class="text-start d-flex justify-content-between chat-single px-5 py-2"
        >
          <div class="d-flex gap-2 ">
            <div class="avatar">
              <img
                class="avatar-img h-40-px w-40-px max-un"
                src="{{ item?.profileImage }}"
                [alt]="item?.Username || item?.FirstName"
                onerror="this.onerror=null;this.src='/assets/images/avtar/placeholder-user.png';"
              />
            </div>
            <div
              class="text-area d-flex justify-content-between sidebar-items align-items-center"
            >
              <div class="title-area">
                <h6 class="m-0">
                  {{ item?.groupName }}
                </h6>
              </div>
            </div>
          </div>
        </div>
        <button
          type="button"
          class="btn btn-sm btn-success"
          (click)="addUserToGroup(item)"
        >
          Add
        </button>
      </div>
    </div>
  </div>
</div>

<div class="modal-footer justify-content-end gap-2">
  <!-- <button type="button" class="btn btn-sm btn-danger" (click)="activeModal.close('cancel')">
      {{ cancelButtonLabel }}
    </button> -->

  <!-- <button type="button" class="btn btn-sm btn-success" (click)="createGroup()">
    {{ confirmButtonLabel }}
  </button> -->
</div>
