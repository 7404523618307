<a
  class="post-description post-meta-description mdtxt pt-0 pb-1 text-start"
  [ngClass]="post?.metaimage ? 'chat-meta-link' : ''"
  [href]="post.metalink.startsWith('www') ? 'http://' + post.metalink : post.metalink"
  target="_blank"
  *ngIf="post?.metalink && !post?.metaimage"
  >{{ post.metalink }}</a
>

<div class="border rounded-3 mb-3 chat-meta-card border-dark-subtle" *ngIf="post?.metaimage">
  <a [href]="post.metalink.startsWith('www') ? 'http://' + post.metalink : post.metalink" target="_blank" class="post-img w-100">
    <img
      *ngIf="validateImageUrl(post?.metaimage)"
      loading="lazy"
      data-src="{{ post?.metaimage }}"
      class="w-100 rounded-top-3"
      alt="image"
      onerror="this.onerror=null;this.src='/assets/images/banner/freedom-buzz-high-res.jpeg';"
    />
    <p class="post-description text-start m-2">{{ post?.title }}</p>
    <p class="post-description text-start m-2">
      {{ post?.metadescription }}
    </p>
  </a>
</div>
