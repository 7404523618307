<div class="modal-header border-0">
  <h5 class="text-start">Forgot Password</h5>
  <fa-icon
    [icon]="['fas', 'xmark']"
    class="font-20-px"
    role="button"
    (click)="activeModal.close()"
  />
</div>
<div class="modal-body text-center">
  <div class="mid-area">
    <div class="alert alert-{{ type }}" role="alert" *ngIf="msg">
      {{ msg }}
    </div>
    <form #verifyEmail="ngForm">
      <div class="row g-3">
        <div class="col-xxl-12">
          <div>
            <span class="w-100 text-start" for="confirmEmail"
              >Email (Get verification link)</span
            >
            <input
              type="email"
              class="input-area mt-2"
              id="confirmEmail"
              name="email"
              [(ngModel)]="userEmail"
              #email="ngModel"
              placeholder="Enter your email"
              required
            />
            <div
              class="invalid-feedback d-block"
              *ngIf="submitted && email.errors && email.errors['required']"
            >
              Email is required
            </div>
            <!-- <div
              class="invalid-feedback d-block"
              *ngIf="submitted && email.errors && email.errors['pattern']"
            >
              Please enter vaild email
            </div> -->
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
<div class="modal-footer justify-content-end border-0">
  <button
    id="common-filter-cancel"
    type="button"
    class="cmn-btn alt p-3"
    (click)="activeModal.close(cancelButtonLabel)"
  >
    {{ cancelButtonLabel }}
  </button>
  <button
    id="common-filter-confirm"
    type="submit"
    class="cmn-btn p-3"
    (click)="verifyEmailSend()"
  >
    {{ confirmButtonLabel }}
  </button>
</div>
