<div class="modal-header">
  <h6 class="text-start m-0">{{ title }}</h6>

  <fa-icon [icon]="['fas', 'xmark']" class="font-20-px" role="button" (click)="activeModal.close('cancel')" />
</div>

<div class="modal-body text-start">
  <span>{{ message }}</span>
</div>

<div class="modal-footer justify-content-end gap-2">
  <button type="button" class="btn btn-sm btn-danger" (click)="activeModal.close('cancel')">
    {{ cancelButtonLabel }}
  </button>

  <button type="button" class="btn btn-sm btn-success" (click)="activeModal.close('success')">
    {{ confirmButtonLabel }}
  </button>
</div>
