<div
  class="right-sidebar profile-sidebar cus-scrollbar d-flex flex-column p-5"
  [ngClass]="{
    'mh-100 vh-100': (breakpointService?.screen | async).lg.lessThen
  }"
>
  <ng-container *ngIf="tokenService.getCredentials()">
    <div class="mb-10 mb-sm-0">
      <div class="dashboard-img mb-5">
        <div
          class="expandSection d-flex flex-column align-items-center"
          (click)="collapse.toggle()"
        >
          <img
            class="w-100 rounded-3"
            src="/assets/images/freedom-dashboard.png"
          />
          <span class="mt-3 text-start font-14-px">
            Get paid 1 1776 Token to Post, Reactions, Share and View and Refer
            family and friends to Freedom!
          </span>
          <h6 class="d-inline-flex mt-4 mb-0">{{!isSettingMenuCollapse? '1776 Dashboard':'My Pages & Communities'}}</h6>
          <fa-icon
          [icon]="[
            'fas',
            isSettingMenuCollapse ? 'chevron-up' : 'chevron-down'
          ]"
            [title]="
              !isSettingMenuCollapse
              ? 'Hide My Pages & Communities'
              : 'Show My Pages & Communities'
              "
          />
          <h6 class="h5 d-inline-flex mb-0">{{isSettingMenuCollapse? '1776 Dashboard':'My Pages & Communities'}}</h6>
        </div>

        <div #collapse="ngbCollapse" [(ngbCollapse)]="isSettingMenuCollapse">
          <div
            class="d-flex justify-content-between align-items-center w-100 mb-3"
          >
            <!-- <h6 class="d-inline-flex mb-0">My Pages & Communities</h6> -->

            <!-- <a routerLink="/communities" role="button" class="d-flex align-items-center" (click)="closeSidebar()">
              <fa-icon [icon]="[
              'fas',
              user?.AccountType === 'user' ? 'circle-plus' : 'magnifying-glass'
            ]" class="font-20-px p-1" />
            </a> -->
          </div>
          <ng-container
            *ngIf="communities?.length > 0; else elseNoCommunitiesFound"
          >
            <div class="d-flex flex-column w-100">
              <ng-container *ngFor="let community of communities">
                <a
                  class="d-flex justify-content-start align-items-start h-60-px p-2 mb-2 gap-2 overflow-hidden"
                  role="button"
                  (click)="closeSidebar()"
                  [routerLink]="
                  community?.pageType === 'community' 
                    ? 'communities/' + community?.slug 
                    : community?.pageType === 'trending' 
                      ? 'trendings/' + community?.slug 
                      : 'pages/' + community?.slug
                "
                
                >
                  <img
                    loading="lazy"
                    class="w-48-px h-48-px rounded-3 bg-dark"
                    data-src="{{
                      community?.logoImg ||
                        '/assets/images/avtar/placeholder-user.png'
                    }}"
                    onerror="this.onerror=null;this.src='/assets/images/avtar/placeholder-user.png';"
                    alt="avatar"
                  />

                  <div class="d-flex flex-column">
                    <span class="m-0 text-start text-ellipsis w-152-px h6">{{
                      community.CommunityName
                    }}</span>
                    <p
                      class="m-0 text-start text-secondary text-ellipsis w-152-px"
                    >
                      {{ community.CommunityDescription }}
                    </p>
                  </div>

                  <fa-icon [icon]="['fas', 'chevron-right']" class="ms-auto" />
                </a>
              </ng-container>
            </div>
          </ng-container>
        </div>
      </div>
      <ng-container *ngIf="isSettingMenuCollapse">
        <span class="d-flex mb-0 font-12-px">All Time</span>
        <ul
          class="list-unstyled fs-11 justify-content-around rounded-3 mt-1 mb-3"
        >
          <li>
            <span>Posts</span><br /><span
              class="posts_likes"
              >{{ counts?.all?.['P'] || 0 }}</span
            >
          </li>
          <li>
            <span>Reactions</span><br /><span
              class="posts_likes"
              >{{ counts?.all?.['L'] || 0 }}</span
            >
          </li>
          <li>
            <span>Views</span><br /><span
              class="posts_likes"
              >{{ counts?.all?.['V'] || 0 }}</span
            >
          </li>
          <li>
            <span>Comments</span><br /><span
              class="posts_likes"
              >{{ counts?.all?.['C'] || 0 }}</span
            >
          </li>
        </ul>

        <span class="d-flex mb-0 font-12-px mt-2">Monthly</span>
        <ul
          class="list-unstyled fs-11 justify-content-around rounded-3 mt-1 mb-3"
        >
          <li>
            <span>Posts</span><br /><span
              class="posts_likes"
              >{{ counts?.monthly?.['P'] || 0 }}</span
            >
          </li>
          <li>
            <span>Reactions</span><br /><span
              class="posts_likes"
              >{{ counts?.monthly?.['L'] || 0 }}</span
            >
          </li>
          <li>
            <span>Views</span><br /><span
              class="posts_likes"
              >{{ counts?.monthly?.['V'] || 0 }}</span
            >
          </li>
          <li>
            <span>Comments</span><br /><span
              class="posts_likes"
              >{{ counts?.monthly?.['C'] || 0 }}</span
            >
          </li>
        </ul>

        <div class="amount-dashboard d-flex align-items-center gap-4 px-3 py-2">
          <img src="/assets/images/logo1776.png" class="w-40-px" />
          <span class="font-14-px">0.8750 </span>
          <span class="font-14-px">3.50</span>
          <span class="font-14-px">(1776)</span>
        </div>

        <span
          class="mt-3 mb-3 py-2 px-3 rounded-3 border text-start font-12-px"
        >
          Dashboard only reflects 1776 earned by posting, liking, etc. and does
          not reflect the 1776 Tokens in your offline wallet.
        </span>
      </ng-container>
    </div>
  </ng-container>

  <!-- <ng-container *ngIf="tokenService.getCredentials()">
    <div class="mb-10 mb-sm-0">
      <div class="dashboard-img mb-5">
        <img class="w-100 rounded-3" src="/assets/images/freedom-dashboard.png" />
        <span class="mt-3 text-start font-14-px">
          Get paid 1 1776 Token to Post, Reactions, Share and View and Refer
          family and friends to Freedom!
        </span>
      </div>
  
      <span class="d-flex mb-0 font-12-px">All Time</span>
      <ul class="list-unstyled fs-11 justify-content-around rounded-3 mt-1 mb-3">
        <li>
          <span>Posts</span><br /><span
            class="posts_likes"
            >{{ counts?.all?.['P'] || 0 }}</span
          >
        </li>
        <li>
          <span>Reactions</span><br /><span
            class="posts_likes"
            >{{ counts?.all?.['L'] || 0 }}</span
          >
        </li>
        <li>
          <span>Views</span><br /><span
            class="posts_likes"
            >{{ counts?.all?.['V'] || 0 }}</span
          >
        </li>
        <li>
          <span>Comments</span><br /><span
            class="posts_likes"
            >{{ counts?.all?.['C'] || 0 }}</span
          >
        </li>
        <li>
          <span>Referrals</span><br /><span
            class="posts_likes"
            >{{ counts?.all?.['R'] || 0 }}</span
          >
        </li>
      </ul>
  
      <span class="d-flex mb-0 font-12-px mt-2">Monthly</span>
      <ul class="list-unstyled fs-11 justify-content-around rounded-3 mt-1 mb-3">
        <li>
          <span>Posts</span><br /><span
            class="posts_likes"
            >{{ counts?.monthly?.['P'] || 0 }}</span
          >
        </li>
        <li>
          <span>Reactions</span><br /><span
            class="posts_likes"
            >{{ counts?.monthly?.['L'] || 0 }}</span
          >
        </li>
        <li>
          <span>Views</span><br /><span
            class="posts_likes"
            >{{ counts?.monthly?.['V'] || 0 }}</span
          >
        </li>
        <li>
          <span>Comments</span><br /><span
            class="posts_likes"
            >{{ counts?.monthly?.['C'] || 0 }}</span
          >
        </li>
        <li>
          <span>Referrals</span><br /><span
            class="posts_likes"
            >{{ counts?.monthly?.['R'] || 0 }}</span
          >
        </li>
      </ul>
  
      <div class="amount-dashboard d-flex align-items-center gap-4 px-3 py-2">
        <img src="/assets/images/logo1776.png" class="w-40-px" />
        <span class="font-14-px">0.8750 </span>
        <span class="font-14-px">3.50</span>
        <span class="font-14-px">(1776)</span>
      </div>
  
      <span class="mt-3 mb-3 py-2 px-3 rounded-3 border text-start font-12-px">
        Dashboard only reflects 1776 earned by posting, liking, etc. and does not
        reflect the 1776 Tokens in your offline wallet.
      </span>
  
      <div
        class="d-flex justify-content-between align-items-center w-100 mb-3 pt-5"
      >
        <h6 class="d-inline-flex mb-0">My Communities</h6>
  
        <a
          routerLink="/communities"
          role="button"
          class="d-flex align-items-center"
          (click)="closeSidebar()"
        >
          <fa-icon
            [icon]="[
              'fas',
              user?.AccountType === 'user' ? 'circle-plus' : 'magnifying-glass'
            ]"
            class="font-20-px p-1"
          />
        </a>
      </div>
  
      <ng-container *ngIf="communities?.length > 0; else elseNoCommunitiesFound">
        <div class="d-flex flex-column w-100">
          <ng-container *ngFor="let community of communities">
            <div
              class="d-flex justify-content-start align-items-start h-60-px p-2 mb-2 gap-2 overflow-hidden"
              role="button"
              (click)="goToCommunityDetails(community)"
            >
              <img
                loading="lazy"
                class="w-48-px h-48-px rounded-3 bg-dark"
                data-src="{{
                  community?.logoImg ||
                    '/assets/images/avtar/placeholder-user.png'
                }}"
                onerror="this.onerror=null;this.src='/assets/images/avtar/placeholder-user.png';"
                alt="avatar"
              />
  
              <div class="d-flex flex-column">
                <span class="m-0 text-start text-ellipsis w-152-px">{{
                  community.CommunityName
                }}</span>
                <p class="m-0 text-start text-secondary text-ellipsis w-152-px">
                  {{ community.CommunityDescription }}
                </p>
              </div>
  
              <fa-icon [icon]="['fas', 'chevron-right']" class="ms-auto" />
            </div>
          </ng-container>
        </div>
      </ng-container>
    </div>
  </ng-container> -->
  <ng-container *ngIf="!tokenService.getCredentials()">
    <div class="dashboard-img mb-5">
      <img class="w-100 rounded-3" src="/assets/images/freedom-dashboard.png" />
      <span class="mt-3 text-start font-14-px">
        Get paid 1 1776 Token to Post, Reactions, Share and View and Refer
        family and friends to Freedom!
      </span>
    </div>
    <div class="w-100 mt-3 d-grid" (click)="closeSidebar()">
      <button class="btn btn-success mb-2" routerLink="/login">Login</button>
      <button class="btn btn-primary" routerLink="/register">Sign up</button>
    </div>
  </ng-container>
</div>
<ng-template #elseNoCommunitiesFound>
  <app-inline-loader *ngIf="isCommunitiesLoader" />
</ng-template>
