<div class="modal-header">
  <h5 class="text-start m-0">Claim 50 Free 1776 Tokens</h5>
  <fa-icon [icon]="['fas', 'xmark']" class="font-20-px" role="button" (click)="activeModal.close()" />
</div>
<div class="modal-body text-left">
  <div class="mid-area">
    <div class="p-3 gap-3 d-grid">
      <span>1. Download 1776 Token Wallet.</span>
      <span>2. Click Send/Copy and copy 1776 payment address.</span>
      <span>3. Open a email message and email sales&#64;Freedom.Buzz </span>
      <span>4. Paste the 1776 Payment address in the email. </span>
      <!-- <span
        >5. Go to
        <a href="https://localfoods.market/" target="_blank"
          >www.LocalFoods.market</a
        >
        and shop!</span
      > -->
      <span>5. Send the email and well send you 50 free 1776 Tokens</span>
    </div>
  </div>
</div>
<div class="modal-footer justify-content-end">
  <button
    id="common-filter-cancel"
    type="button"
    class="cmn-btn alt p-2"
    (click)="activeModal.close(cancelButtonLabel)"
  >
    {{ cancelButtonLabel }}
  </button>
</div>
