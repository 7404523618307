<div class="modal-header border-0">
  <h5 class="text-start">{{ title }}</h5>
  <fa-icon
    [icon]="['fas', 'xmark']"
    class="xxltxt"
    role="button"
    (click)="activeModal.close()"
  />
</div>
<div class="single-box d-flex flex-column p-5 mb-3">
  <form
    [formGroup]="researchForm"
    *ngIf="researchForm"
    autocomplete="off"
    role="form"
    class="form-dark d-flex flex-column row"
  >
    <div class="col form-group">
      <label for="posttoprofileid" class="form-label"
        >Select Group <span class="text-danger">*</span></label
      >
      <select
        class="input-area w-100"
        id="posttoprofileid"
        formControlName="posttoprofileid"
        placeholder="Select select group"
        trim="blur"
      >
        <option *ngFor="let research of researches" [value]="research?.ID">
          {{ research?.FirstName }}
        </option>
      </select>

      <div
        *ngIf="isFormSubmittedAndError('posttoprofileid')"
        class="invalid-feedback"
      >
        <div *ngIf="isFormSubmittedAndError('posttoprofileid', 'required')">
          This field is required.
        </div>
      </div>
    </div>

    <div class="col form-group">
      <label for="postdescription" class="form-label"
        >Type or paste the Title or Description (no links)
        <span class="text-danger">*</span></label
      >
      <app-tag-user-input
        id="postdescription"
        placeholder="What's going on?"
        [isShowMetaPreview]="false"
        [isAllowTagUser]="true"
        [value]="tagInputDefaultData"
        [isShowMetaLoader]="false"
        (onDataChange)="onTagUserInputDescription($event, 'postdescription')"
      />
      <div
        *ngIf="isFormSubmittedAndError('postdescription')"
        class="invalid-feedback"
      >
        <div *ngIf="isFormSubmittedAndError('postdescription', 'required')">
          This field is required.
        </div>
      </div>
    </div>

    <div class="col form-group">
      <label for="textpostdesc" class="form-label"
        >Paste article or video link</label
      >
      <app-tag-user-input
        id="textpostdesc"
        placeholder="Paste article or video link..."
        [isShowMetaPreview]="true"
        [isAllowTagUser]="false"
        [value]="tagInputDefaultPostData"
        [isShowMetaLoader]="true"
        (onDataChange)="onTagUserInputChangeEvent($event, 'textpostdesc')"
      />
    </div>

    <div class="d-flex">
      <div
        class="col form-group"
        *ngIf="
          !this.researchForm.get('meta').value?.metaimage &&
          !(selectedpdfFile || postFileUrl)
        "
      >
        <label for="textpostdesc" class="form-label">Upload Image</label>
        <div class="d-flex custom-input-area" *ngIf="!postImageUrl">
          <fa-icon
            [icon]="['fas', 'image']"
            class="text-primary cursor font-24-px"
            (click)="uploadImage.click()"
          />
          <input
            #uploadImage
            class="d-none"
            type="file"
            id="fileInput"
            (change)="onFileSelected($event)"
            accept="image/*"
          />
        </div>
        <ng-container *ngIf="postImageUrl">
          <div class="position-relative w-100-px h-100-px">
            <img
              loading="lazy"
              data-src="{{ postImageUrl }}"
              class="w-100 h-100 rounded-4"
              alt="icon"
              onerror="this.onerror=null;"
            />
            <fa-icon
              [icon]="['fas', 'xmark']"
              class="position-absolute top-0 start-100 translate-middle badge bg-danger p-1 font-12-px c-pointer c-icon-color"
              role="button"
              (click)="removeImgFile()"
            />
          </div>
        </ng-container>
      </div>
      <div class="col form-group" *ngIf="!postImageUrl">
        <label for="textpostdesc" class="form-label">Upload File</label>
        <div
          class="d-flex custom-input-area"
          *ngIf="!(selectedpdfFile || postFileUrl)"
        >
          <fa-icon
            [icon]="['fas', 'file']"
            class="text-primary cursor font-24-px"
            (click)="pdfFile.click()"
          />
          <input
            type="file"
            #pdfFile
            hidden
            class="d-none"
            accept="application/pdf"
            (change)="onPostFileSelect($event)"
          />
        </div>
        <ng-container *ngIf="selectedpdfFile || postFileUrl">
          <div class="position-relative w-250-px h-100-px mt-5">
            <div class="d-flex align-items-center">
              <fa-icon
                [icon]="['fas', 'file-pdf']"
                class="c-pointer close-icon font-40-px"
              />
              <fa-icon
                [icon]="['fas', 'xmark']"
                class="position-absolute top-0 start-100 translate-middle badge bg-danger p-1 font-12-px c-pointer c-icon"
                role="button"
                (click)="removePostSelectedFile()"
              />
              <ng-container *ngIf="pdfName; else selectedFilename">
                <span class="c-pdf-show">{{
                  pdfName.replaceAll("%", " ")
                }}</span>
              </ng-container>
              <ng-template #selectedFilename>
                <span class="c-pdf-show">{{ selectedpdfFile.name }}</span>
              </ng-template>
            </div>
          </div>
        </ng-container>
      </div>
    </div>

    <div class="col form-group">
      <label for="keywords" class="form-label"
        >Enter upto 5 tags separated by a comma</label
      >
      <input
        type="text"
        class="input-area"
        placeholder="Enter Keywords"
        formControlName="keywords"
        (input)="onChangeTag($event)"
      />
    </div>

    <div class="col-12 d-flex justify-content-end">
      <!-- <button
            class="btn btn-sm btn-secondary px-3 py-1 d-flex justify-content-center align-items-center gap-2 me-2"
            *ngIf="
              researchForm?.get('posttoprofileid').value ||
              researchForm?.get('postdescription').value ||
              researchForm?.get('textpostdesc').value ||
              tagInputDefaultData
            "
            (click)="resetPost()"
          >
            Cancel
            <fa-icon class="c-icon-color" [icon]="['fas', 'xmark']" />
          </button> -->
      <!-- <button
            [disabled]="isGroupPostsLoader"
            class="btn btn-sm btn-primary px-3 py-1 d-flex justify-content-center align-items-center gap-2"
            (click)="createImagePost()"
          >
            Post
            <fa-icon
              [icon]="['fas', 'paper-plane']"
              class="font-20-px c-icon-color"
            />
          </button> -->
    </div>
  </form>
</div>
<div class="modal-footer justify-content-end gap-3 border-0">
  <button
    id="common-filter-cancel"
    type="button"
    class="cmn-btn alt p-2"
    (click)="activeModal.close('Cancel')"
  >
    <!-- Cancel -->
    {{ cancelButtonLabel }}
  </button>
  <button
    id="common-filter-confirm"
    type="submit"
    class="cmn-btn p-2"
    (click)="createImagePost()"
  >
    <!-- Create -->
    {{ confirmButtonLabel }}
  </button>
</div>
